import {
  useMemo,
  useCallback,
} from 'react';
import {
  useAccount,
  useNetwork,
  useSwitchNetwork,
} from 'wagmi';
import { useWeb3Modal } from '@web3modal/react';
import { ReactComponent as Ethereum } from '../../../assets/images/currency/ETH.svg'
import { ReactComponent as MetamaskIcon } from '../../../assets/images/wallets/metamask.svg';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';

const Connected = () => {
  const { address } = useAccount();
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();
  const { open } = useWeb3Modal();
  const { t } = useTranslation('header');
  

  const shortAddressMobile = useMemo(() => {
    if (!address) {
      return '';
    }
    return `${address.slice(0, 2)}...${address.slice(-4)}`;
  },[address]);

  const shortAddressDesktop = useMemo(() => {
    if (!address) {
      return '';
    }
    return `${address.slice(0, 5)}...${address.slice(-4)}`;
  },[address]);

  const isConnecting = useRef(false);

  const walletClickHandler = useCallback(() => {
    if (isConnecting.current) return;
    isConnecting.current = true;

    const isMetaMask = typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask;
    const isWalletConnect = typeof window.ethereum !== 'undefined' &&
    (window.ethereum?.isWalletConnect ||
      (window.ethereum?.providers && Array.isArray(window.ethereum.providers) &&
        window.ethereum.providers.some((p: any) => p.isWalletConnect)));

    // !!! WalletConnect NOT SUPPORT switchNetwork
    if (isWalletConnect) {
        console.log('WalletConnect — NOT SUPPORT не поддерживается');
        isConnecting.current = false;
        return;
    }
    
    if (isMetaMask && chain?.id !== 1) {
      if (!switchNetwork) {
        alert(`switch not supported`);
        open();
        isConnecting.current = false;
        return;
      }  

      try {
        switchNetwork?.(1);
      } catch (error) {
        alert(`switch error fallback`);
        console.error('Error during network switching :', error);
        setTimeout(() => open(), 800);
      } finally {
        isConnecting.current = false;
      }
      return;
    }
    open();
    isConnecting.current = false;
  }, [chain, open, switchNetwork]);

  return (
    <div className="flex items-center cursor-pointer" onClick={walletClickHandler}>
      <div className="flex flex-col gap-1 mr-2">
        <div className="flex items-center pl-[3px] text-blue text-[14px] font-semibold tablet:hidden ">
          {/* <MetamaskIcon className="mr-1" /> */}
          <span className={`w-3 h-3 mr-1 rounded-full ${chain?.id === 1 ? 'bg-green-300' : 'bg-red-500'}`}></span>
          <img src="/wallet.png" className="mr-1" alt="X" width={15} height={15} />
          {shortAddressMobile}
        </div>
        <div className="hidden gap-1 items-center pl-[3px] text-blue text-[14px] font-semibold tablet:flex">
          {/* <MetamaskIcon className="mr-1" /> */}
          <span className={`w-3 h-3 mr-1 rounded-full ${chain?.id === 1 ? 'bg-green-300' : 'bg-red-500'}`}></span>
          <img src="/wallet.png" className="mr-1" alt="X" width={15} height={15} />
          {shortAddressDesktop}
        </div>

        {
          chain?.id === 1
            ? (
              <div className="flex items-center text-[12px] font-medium">
                <Ethereum className="mr-1 w-4 h-4" />
                Ethereum
              </div>
            )
            : (
              <div className="text-[12px] font-medium">
                Switch to ethereum
              </div>
            )
        }
      </div>
    </div>
  )
};

export default Connected;
