import React, {
  useState,
  useCallback,
} from 'react';
import { Tooltip } from 'flowbite-react';
import { ReactComponent as QuestionMark } from '../../assets/images/common/question.svg';
import { useTranslation } from 'react-i18next';
import { RotatingLines } from 'react-loader-spinner'


// icons
import dropdownIcon from '../../assets/images/common/dropdown.png';
import { ReactComponent as USDT } from '../../assets/images/currency/USDT.svg';
import { ReactComponent as USDC } from '../../assets/images/currency/USDC.svg';
import { ReactComponent as DAI } from '../../assets/images/currency/DAI.svg';

// components
import Card from '../../components/Card';
import PendingTransactionsList from './PendingTransactionsList';
import MixedToken from '../../components/MixedToken';
import CurrencyIcon from "../../components/CurrencyIcon";

interface DepositProps {
  depositAmount: string;
  interestRate: number;
  currentInterestRate: number;
  pendingDeposits: Array<any>;
  pendingWithdrawals: Array<any>;
  availableToWithdraw: number;
  personalApy: string;
}

const Deposits = (props: DepositProps) => {
  const {
    depositAmount,
    interestRate,
    currentInterestRate,
    pendingDeposits,
    pendingWithdrawals,
    availableToWithdraw,
    personalApy
  } = props;
  const [opened, setOpened] = useState<boolean>(false);
  const { t } = useTranslation('dashboard');

  //const userDepositsAmount = Object.values(depositAmount).reduce((acc, curr) => acc + curr, 0);

  //правка 1
  const userDepositsAmount = parseFloat(depositAmount);
  // const userDepositsAmount = 2; // использовал значение для тестов лоадера на депозите

  const triggerDropdown = useCallback(() => {
    setOpened(!opened);
  }, [opened]);

  return (
    <>
      <Card>
        <div className="flex items-center tablet:justify-between cursor-pointer tablet:cursor-auto" onClick={triggerDropdown}>
          <h2 className="text-gray-900 text-[24px] leading-8 font-bold">{t('deposits')}</h2>          
          <div className="bg-gray-100 relative py-2 px-4 text-gray-900 rounded-2xl font-semibold ml-3">
            {isNaN(userDepositsAmount)? (
              <div className="bg-none w-[40px] h-[25px] rounded-3xl shadow-lg flex flex-row items-center justify-center px-[0px]">
                <RotatingLines
                  strokeColor="#3956FE"
                  strokeWidth="3"
                  animationDuration="0.75"
                  width="25"
                  visible={true}
                />
                <div className="text-[#3956FE] text-[8px] font-bold text-center ml-[10px]">
                  {/* {t('loader_content')} */}
                </div>
              </div>
            ) :` ${'$'+userDepositsAmount.toFixed(2)}`}
            <span className="absolute top-[-10px] right-[-10px]">
              <Tooltip
                content={t('deposit_tooltip')}
                style="light"
                arrow={false}
                className="w-[300px]"
              >
                <QuestionMark className="ml-2 h-5 w-5"/>
              </Tooltip>
            </span>
          </div>
          <div className="ml-auto tablet:hidden">
            <img src={dropdownIcon} alt="" className={opened ? '' : 'rotate-180'} />
          </div>
        </div>
        <div className={`${opened ? 'block' : 'hidden'} tablet:block`}>
          <hr className="my-6 tablet:hidden" />
          <div className="overflow-scroll max-h-[136px] tablet:hidden">
            <PendingTransactionsList pendingTransactions={[...pendingDeposits, ...pendingWithdrawals]} />
          </div>
        </div>
      </Card>
      <div className="mt-6">
        <Card>
        <div className="flex flex-row items-center justify-between w-[100%]">
          <h2 className="text-gray-900 text-[24px] leading-8 font-bold flex-1">{t('total_balance')}</h2>
          <div className="flex flex-row items-center justify-between flex-0 relative">
            <div className="online-span">
              <p className="blinking-dot" >•</p>
              {window.screen.width < 800 ? null :<span>{t('online')}</span>}
            </div>
            <span className="absolute top-[-10px] right-[-10px]">
              <Tooltip
                content={t('online-withdraw-tooltip')}
                style="light"
                arrow={false}
                className="w-[300px]"
              >
                <QuestionMark className="ml-2 h-5 w-5"/>
              </Tooltip>
            </span>
          </div>
        </div>
        <div className="flex flex-col gap-4 bg-gray p-4 rounded-2xl mt-4 font-medium relative">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <CurrencyIcon currency="MIXED"/>
              <span className="absolute top-[-10px] right-[-10px]">
                <Tooltip
                  content={t('total_balance_hint')}
                  style="light"
                  arrow={false}
                  className="w-[300px]"
                >
                  <QuestionMark className="ml-2 h-5 w-5"/>
                </Tooltip>
              </span>
            </div>
              <span className="text-gray-900 font-semibold">
                {(userDepositsAmount + (availableToWithdraw || 0)).toFixed(2)}
              </span>
            </div>
          </div>

          {Number(personalApy) > 0 && (
            <div
              className="flex items-center justify-between bg-gray-100 px-4 py-3 font-medium rounded-2xl mt-4 relative">
                <span>
                  {t('personal_apy')}
                </span>
              <span className="text-[#73D516] font-semibold">
                  {personalApy}%
                </span>
              <span className="absolute top-[-10px] right-[-10px]">
                <Tooltip
                  content={t('personal_apy_hint')}
                  style="light"
                  arrow={false}
                  className="w-[300px]"
                >
                  <QuestionMark className="ml-2 h-5 w-5"/>
                </Tooltip>
              </span>
            </div>
          )}
          <div
            className="flex items-center justify-between bg-gray-100 px-4 py-3 font-medium rounded-2xl mt-4 relative">
              <span>
                {t('current_interest_rate')}
              </span>
            <span className="text-gray-900 font-semibold">
                {currentInterestRate}%
              </span>
            <span className="absolute top-[-10px] right-[-10px]">
                <Tooltip
                  content={t('interest_rate_hint')}
                  style="light"
                  arrow={false}
                  className="w-[300px]"
                >
                  <QuestionMark className="ml-2 h-5 w-5"/>
                </Tooltip>
              </span>
          </div>
          <div
            className="flex items-center justify-between bg-gray-100 px-4 py-3 font-medium rounded-2xl mt-4 relative">
              <span>
                {t('interest_rate')}
              </span>
            <span className="text-gray-900 font-semibold">
                {interestRate}%
              </span>
            <span className="absolute top-[-10px] right-[-10px]">
                <Tooltip
                  content={t('year_interest_rate_hint')}
                  style="light"
                  arrow={false}
                  className="w-[300px]"
                >
                  <QuestionMark className="ml-2 h-5 w-5"/>
                </Tooltip>
              </span>
          </div>
          
        </Card>
      </div>
      <div className="hidden tablet:block tablet:sticky mt-6">
        <Card>
          <div className="overflow-scroll max-h-[176px]">
            <PendingTransactionsList pendingTransactions={[...pendingDeposits, ...pendingWithdrawals]} />
          </div>
        </Card>
      </div>
    </>
  )
};

export default Deposits;
