import {
  useMemo,
  useCallback,
} from 'react';
import { Link } from 'react-router-dom';
import {
  usePrepareContractWrite,
  useContractWrite,
} from 'wagmi';
import dsfAddresses from '../../utils/dsf_addresses.json';
import dsfAbi from '../../utils/dsf_abi.json';
import { useTranslation } from 'react-i18next';

// icons
import { ReactComponent as LinkIcon } from '../../assets/images/common/link.svg';
import { ReactComponent as ETHIcon } from '../../assets/images/currency/ETH.svg';

// components
import Card from '../../components/Card';
import CompletedTransactionContent from './CompletedTransactionContent';
import IncompletedTransactionContent from './IncompletedTransactionContent';
import CompletedTransactionCoinContent from './CompletedTransactionCoinContent';
import IncompletedTransactionCoinContent from './IncompletedTransactionCoinContent';

import type { TransactionStatus, TransactionActionType } from '../../types/transactions';

interface TransactionProps {
  timestamp: Date;
  transactionStatus: TransactionStatus;
  transactionAction: TransactionActionType;
  transactionToken: StableType;
  transactionAmount: number;
  amountPlaced?: number;
  lpShares?: number;
  commissionEth: number;
  commissionUsd: number;
  isFinished: boolean;
  txHash: string;
  address: string;
}

const Transaction = (props: TransactionProps) => {
  const {
    timestamp,
    transactionStatus,
    transactionAction,
    transactionToken,
    transactionAmount,
    amountPlaced,
    commissionEth,
    commissionUsd,
    isFinished,
    txHash,
    address,
  } = props;
  const { config } = usePrepareContractWrite({
    address: dsfAddresses.DSF as `0x${string}`,
    abi: dsfAbi,
    functionName: transactionAction === 'pendingDeposit' ? 'removePendingDeposit' : 'removePendingWithdrawal',
  });
  const { write: removeTransactions } = useContractWrite(config);
  const { t } = useTranslation('statistics');

  const transactionActionContent = useMemo(() => {
      return transactionAction;
  }, [transactionAction]);

  const cancelTransaction = useCallback(() => {
    removeTransactions?.();
  }, [removeTransactions]);

  return (
    <Card>
      <div className="mx-[-12px] my-[-21px]">
        <div className="flex items-center justify-between bg-gray rounded-2xl p-2 text-gray-900">
          <div>
            {timestamp.toLocaleString()}
          </div>
          <div className="flex items-center gap-3">
            <span className={`${transactionStatus} capitalize`}>{t(transactionActionContent)}</span>
            <Link to={`https://etherscan.io/tx/${txHash}`}>
              <LinkIcon />
            </Link>
          </div>
        </div>
        <div className="flex justify-between bg-gray rounded-2xl p-2 text-gray-900 mt-2">
        <span className="text-default">
          {t('coin')}:
        </span>
          {
            isFinished && amountPlaced
              ? <CompletedTransactionCoinContent
                  transactionAmount={transactionAmount}
                  amountPlaced={amountPlaced}
                  transactionToken={transactionToken}
                  transactionAction={transactionAction}
                />
              : <IncompletedTransactionCoinContent
                  transactionAmount={transactionAmount}
                  transactionToken={transactionToken}
                />
          }
        </div>
        <div className="flex justify-between bg-gray rounded-2xl p-2 text-gray-900 mt-2">
        <span className="text-default">
          {t('Commissions_spent')}:
        </span>
          <div className="flex items-center gap-2">
            <ETHIcon />
            <span>
            {`${commissionEth.toLocaleString('en-US')} `}
              ETH
          </span>
            <span className="text-default">
            $ {(commissionUsd).toLocaleString('en-US')}
          </span>
          </div>
        </div>
        <div className='tx-fixs4'>
        {
          isFinished
            ? <CompletedTransactionContent 
              transactionAction={transactionAction}
              transactionStatus={transactionStatus}
              address={address}
            />
            : <IncompletedTransactionContent
              transactionAction={transactionAction}
              cancelTransaction={cancelTransaction}
              isDisableToCancel={false}
              timestamp={timestamp}
            />
        }
        </div>
      </div>
    </Card>
  )
};

export default Transaction;
