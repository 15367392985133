import {
  useCallback,
  useState,
  useEffect,
  useRef, useMemo,
} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ethers } from 'ethers';
import { useAccount } from 'wagmi';
import {Trans, useTranslation} from 'react-i18next';
import type { TransactionStatus } from '../types/transactions';

import { ReactComponent as ArrowLeftIcon } from '../assets/images/common/arrow_left.svg';

// styles
import './styles/transactions.css';

// components
import Card from '../components/Card';
import Transaction from './transactions/Transaction';
import DesktopTransaction from './transactions/DesktopTransaction';

import type { TransactionAPI } from '../types/transactions';

const TransactionsStatisticsPage = () => {
  const [transactions, setTransactions] = useState<Array<TransactionAPI>>([]);
  const { address } = useAccount();
  // const address = '0x9b744249E4BA090d6231497f43446EAE7046aE83'
  const { t }  = useTranslation('statistics');

  const getTransactions = useCallback(async () => {
    if (!address) return [];
  
    const url = `https://api2.dsf.finance/request/transactions?wallet=${address}&apikey=a5d8b4f2-6e3a-49b1-8c5e-3d7b2a4c6f1e`;
    console.log(url);

    try {
      const response = await axios.get(url);
      return response.data || [];
    } catch (error) {
      console.error('Error fetching transactions:', error);
      return [];
    }
  }, [address]);

  // useEffect(() => {
  //   getTransactions().then(setTransactions);
  // }, [getTransactions]);

  useEffect(() => {
    getTransactions().then((data) => {
      console.log('Fetched transactions:', data); // 👈 Лог
      setTransactions(data);
    });
  }, [getTransactions]);

  return (
    <>
      <div className="flex flex-col tablet:flex-row tablet:justify-between tablet:items-center">
        <div>
          <h2 className="text-gray-900 font-bold text-[24px] leading-8">
            {t('transactions_statistics')}
          </h2>
          <Link to="/dashboard" className="flex items-center gap-2 mt-5 text-gray-900 cursor-pointer">
            <ArrowLeftIcon/>
            <Trans t={t} ns="deposit">
              back_<span className="font-bold">dashboard</span>
            </Trans>
          </Link>
        </div>
        <div>
          {/*filters*/}
        </div>
      </div>
      {/* Mobile view */}
      <ul className="flex flex-col gap-3 text-[14px] leading-[20px] mt-4 tablet:hidden">
          {transactions.map((tx) => (
            <li key={tx.id}>
              <Transaction
                timestamp={new Date(tx.unixtime * 1000)}
                transactionStatus={tx.status ?? 'pending'}
                transactionAction={tx.action}
                transactionToken={tx.currency as StableType}
                transactionAmount={+tx.amount}
                amountPlaced={+tx.amount}
                commissionUsd={tx.gasSpentUsd || 0}
                commissionEth={tx.gasSpentEth || 0}
                isFinished={tx.status === 'standard' || tx.status === 'completed' || tx.status === 'cancelled' || tx.status === 'failed' || tx.status === 'transfered' }
                txHash={tx.txHash}
                lpShares={tx.lpShares || 0}
                address={tx.to || tx.from || ''}
              />
            </li>
          ))}
      </ul>
      {/* Desktop view */}
      <div className="hidden tablet:block mt-4">
        <Card>
          <table className="text-[14px] leading-[20px] w-full border-separate border-spacing-y-2">
            <thead>
            <tr>
              <th className="text-left font-normal tx-fixs2">{t('date')}</th>
              <th className="text-left font-normal">{t('operation_type')}</th>
              <th className="text-left font-normal">{t('coin')}</th>
              <th className="text-left font-normal">{t('Commissions_spent')}</th>
              <th className="text-left font-normal tx-fixs3">{t('status')}</th>
              <th />
              <th />
            </tr>
            </thead>
            <tbody className="text-gray-900">
            {transactions.map((tx) => (
                <DesktopTransaction
                  key={tx.id}
                  timestamp={new Date(tx.unixtime * 1000)}
                  transactionStatus={tx.status ?? 'pending'}
                  transactionAction={tx.action}
                  transactionToken={tx.currency as StableType}
                  transactionAmount={+tx.amount}
                  amountPlaced={+tx.amount}
                  commissionUsd={tx.gasSpentUsd || 0}
                  commissionEth={tx.gasSpentEth || 0}
                  isFinished={tx.status === 'standard' || tx.status === 'completed' || tx.status === 'cancelled' || tx.status === 'failed' || tx.status === 'transfered' }
                  txHash={tx.txHash}
                  lpShares={tx.lpShares || 0}
                  address={tx.to || tx.from || ''}
                />
              ))}
            </tbody>
          </table>
        </Card>
      </div>
    </>
  );
};

export default TransactionsStatisticsPage;
