import { useCallback, useEffect } from 'react';
import {
  usePrepareContractWrite,
  erc20ABI,
  useContractWrite,
} from 'wagmi';
import { ethers } from 'ethers';
import dsfAddresses from '../utils/dsf_addresses.json';
import usdtAbi from '../utils/usdt_abi.json';


const useApprove = (tokenAddress: `0x${string}`) => {
  const { config, error } = usePrepareContractWrite({
    chainId: 1,
    address: tokenAddress as `0x${string}`,
    abi: usdtAbi,
    functionName: 'approve',
    args: [dsfAddresses.DSF as `0x${string}`, ethers.MaxUint256],
  });
  
  const {
    isLoading,
    isSuccess,
    writeAsync: approveAsync,
  } = useContractWrite(config);

  const approve = useCallback(async () => {
    if (!approveAsync) {
      console.warn('approveAsync is not available');
      return;
    }

    try {
      const tx = await approveAsync();
      console.log('Approve tx sent:', tx.hash);
      return tx;
    } catch (err: any) {
      console.error('Approve failed:', err);
      throw err;
    }
  }, [approveAsync]);

  return {
    approve,
    isLoading,
    isSuccess,
    error,
  };
};

export default useApprove;
