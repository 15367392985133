type UserContext = {
    address?: string;
    networkId?: number;
    deviceInfo: {
        userAgent: string;
        language: string;
        platform: string;
        screenResolution: string;
        timeZone: string;
        deviceMemory?: number;
        hardwareConcurrency?: number;
        cookiesEnabled: boolean;
        doNotTrack?: string | null;
        connectionType?: string;
    };
    browserInfo: {
        vendor: string;
        version: string;
        languages: readonly string[];
        onLine: boolean;
        pdfViewerEnabled: boolean;
    };
    appInfo: {
        url: string;
        pathname: string;
        referrer: string;
        previousUrl?: string;
        timestamp: string;
    };
};

type WarningPattern = RegExp | string;

class Logger {
    private static endpoint = "https://api2.dsf.finance/send-message?apikey=f3b2a4a6-541d-4c9e-9d6c-2f3b1a4a6d2c";
    private static ignoredWarnings: WarningPattern[] = [
        /Each child in a list should have a unique "key" prop/,
        "Warning: ReactDOM.render is no longer supported",
        "Warning: Cannot update during an existing state transition",
        /Warning: React does not recognize the.*prop/,
        "DSF: Wrong out lp Ratio",
        'The contract function "calcLpRatioSafe" reverted with the following reason:',
        'Address "null" is invalid.',
        /ContractFunctionExecutionError.*DSF: Wrong out lp Ratio/,
        'Cannot convert null to a BigInt',
        'Address "undefined" is invalid.'
    ];
    private static isInitialized = false;
    private static originalConsoleError = console.error;
    private static userContext: UserContext | null = null;
    private static sessionLogged = false;
    
    // Хранилище для отслеживания отправленных ошибок
    private static sentErrors = new Map<string, {
        timestamp: number;
        count: number;
    }>();
    
    // Время в миллисекундах, в течение которого дубликаты ошибки игнорируются
    private static errorCooldown = 3600000; // 1 час
    
    // Максимальное количество одинаковых ошибок, отправляемых в период cooldown
    private static maxErrorCount = 1;

    static setUserContext(context: Partial<UserContext>) {
        Logger.userContext = {
            ...Logger.userContext,
            ...context
        } as UserContext;
        
        // Проверяем, есть ли address в обновленном контексте и отправляем логи о начале сессии
        if (Logger.userContext?.address && !Logger.sessionLogged) {
            Logger.logSessionStart();
        }
    }

    private static collectBrowserData(): UserContext {
        const nav = navigator as Navigator & {
            deviceMemory?: number;
            hardwareConcurrency?: number;
            connection?: { effectiveType: string };
        };
        return {
            deviceInfo: {
                userAgent: nav.userAgent,
                language: nav.language,
                platform: nav.platform,
                screenResolution: `${window.screen.width}x${window.screen.height}`,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                deviceMemory: nav.deviceMemory,
                hardwareConcurrency: nav.hardwareConcurrency,
                cookiesEnabled: navigator.cookieEnabled,
                doNotTrack: navigator.doNotTrack,
                connectionType: nav.connection?.effectiveType
            },
            browserInfo: {
                vendor: navigator.vendor,
                version: navigator.appVersion,
                languages: navigator.languages,
                onLine: navigator.onLine,
                pdfViewerEnabled: navigator.pdfViewerEnabled || false
            },
            appInfo: {
                url: window.location.href,
                pathname: window.location.pathname,
                referrer: document.referrer,
                previousUrl: document.referrer,
                timestamp: new Date().toISOString()
            }
        };
    }

    /**
     * Логирует начало сессии пользователя, если доступен адрес
     */
    static async logSessionStart() {
        // Проверяем наличие адреса
        if (!Logger.userContext?.address) {
            console.log('Отсутствует адрес пользователя, логирование сессии пропущено');
            return;
        }
        
        // Отмечаем, что сессия уже была залогирована
        Logger.sessionLogged = true;
        
        // Собираем данные о браузере
        const browserData = Logger.collectBrowserData();
        const contextData = {
            ...browserData,
            ...Logger.userContext
        };

        try {
            const response = await fetch("https://api2.dsf.finance/send-message-session?apikey=f3b2a4a6-541d-4c9e-9d6c-2f3b1a4a6d2c", {
                method: "POST",
                headers: { 
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: JSON.stringify({
                    message: "Session started",
                    stack: 'none',
                    info: `User session started with address: ${Logger.userContext.address}`,
                    context: contextData,
                    timestamp: new Date().toISOString(),
                    type: "session_start"  // Добавляем тип для различения от ошибок
                })
            });
            if (!response.ok) {
                console.error('Ошибка отправки лога о начале сессии:', await response.text());
            } else {
                console.log('Лог о начале сессии успешно отправлен');
            }
        } catch (fetchError) {
            console.error('Ошибка при отправке лога о начале сессии:', fetchError);
        }
    }

    /**
     * Создает уникальный ключ для ошибки, учитывая сообщение и первые два вызова стека
     */
    private static createErrorKey(error: Error): string {
        // Получаем первые два элемента стека для идентификации контекста ошибки
        const stackLines = error.stack?.split('\n').slice(0, 3).join('') || '';
        // Создаем ключ на основе сообщения и упрощенного стека
        return `${error.message}:${stackLines}`;
    }

    /**
     * Проверяет, нужно ли отправлять ошибку или она считается дубликатом
     */
    private static shouldSendError(errorKey: string): boolean {
        const now = Date.now();
        const errorInfo = Logger.sentErrors.get(errorKey);
        
        // Если ошибка отправлялась ранее
        if (errorInfo) {
            // Проверяем, прошло ли достаточно времени с момента последней отправки
            const timeSinceLastError = now - errorInfo.timestamp;
            
            // Если период охлаждения прошел, сбрасываем счетчик
            if (timeSinceLastError > Logger.errorCooldown) {
                Logger.sentErrors.set(errorKey, { timestamp: now, count: 1 });
                return true;
            }
            
            // Если не превышен лимит отправок для данной ошибки
            if (errorInfo.count < Logger.maxErrorCount) {
                Logger.sentErrors.set(errorKey, { 
                    timestamp: now, 
                    count: errorInfo.count + 1 
                });
                return true;
            }
            
            // Логируем локально, что ошибка проигнорирована как дубликат
            console.log(`Ignored duplicate error (${errorInfo.count}): ${errorKey}`);
            return false;
        }
        
        // Если ошибка новая, добавляем в Map и разрешаем отправку
        Logger.sentErrors.set(errorKey, { timestamp: now, count: 1 });
        return true;
    }
    
    /**
     * Периодическая очистка старых записей об ошибках
     */
    private static cleanupErrorsMap() {
        const now = Date.now();
        // Используем Array.from для совместимости с более старыми версиями TypeScript/JavaScript
        Array.from(Logger.sentErrors.keys()).forEach(key => {
            const info = Logger.sentErrors.get(key);
            if (info && now - info.timestamp > Logger.errorCooldown) {
                Logger.sentErrors.delete(key);
            }
        });
    }

    static async logError(error: Error, info?: string) {
        const messageStr = error.message;

        // Проверяем, нужно ли игнорировать сообщение по шаблонам
        const shouldIgnore = Logger.ignoredWarnings.some((pattern) => {
            if (pattern instanceof RegExp) {
                return pattern.test(messageStr);
            } else {
                return messageStr.includes(pattern);
            }
        });

        if (shouldIgnore) {
            console.log(`Ignored warning: ${messageStr}`);
            return;
        }
        
        // Создаем уникальный ключ для ошибки
        const errorKey = Logger.createErrorKey(error);
        
        // Проверяем, нужно ли отправлять ошибку
        if (!Logger.shouldSendError(errorKey)) {
            return;
        }

        // Собираем данные о браузере при каждой ошибке
        const browserData = Logger.collectBrowserData();
        const contextData = {
            ...browserData,
            ...Logger.userContext
        };

        try {
            const response = await fetch(Logger.endpoint, {
                method: "POST",
                headers: { 
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: JSON.stringify({
                    message: error.message,
                    stack: error.stack,
                    info,
                    context: contextData,
                    timestamp: new Date().toISOString(),
                    type: "error"  // Добавляем тип для различения от сессии
                })
            });
            if (!response.ok) {
                console.error('Ошибка отправки лога:', await response.text());
            } else {
                console.log('Лог успешно отправлен');
            }
        } catch (fetchError) {
            console.error('Ошибка при отправке лога:', fetchError);
        }
    }

    static initGlobalErrorHandler() {
        if (Logger.isInitialized) {
            console.warn('Logger уже инициализирован');
            return;
        }

        window.onerror = (message, source, lineno, colno, error) => {
            if (error instanceof Error) {
                Logger.logError(error, `Global Error at ${source}:${lineno}:${colno}`);
            } else {
                Logger.logError(new Error(String(message)), `Global Error at ${source}:${lineno}:${colno}`);
            }
        };

        window.onunhandledrejection = (event) => {
            const error = event.reason instanceof Error ? 
                event.reason : 
                new Error(String(event.reason));
            Logger.logError(error, "Unhandled Promise Rejection");
        };

        console.error = (...args: any[]) => {
            const error = args[0];
            if (error instanceof Error) {
                Logger.logError(error, "React Error");
            }
            Logger.originalConsoleError.apply(console, args);
        };
        
        // Запускаем периодическую очистку Map с записанными ошибками каждый час
        setInterval(() => Logger.cleanupErrorsMap(), Logger.errorCooldown);
        
        // Если у нас уже есть адрес при инициализации, сразу отправляем лог о сессии
        if (Logger.userContext?.address && !Logger.sessionLogged) {
            Logger.logSessionStart();
        }

        Logger.isInitialized = true;
    }
    
    /**
     * Сброс счетчиков для тестирования или принудительной отправки ошибок
     */
    static resetErrorTracking() {
        Logger.sentErrors.clear();
        Logger.sessionLogged = false;  // Сбрасываем флаг логирования сессии
    }
    
    /**
     * Установка настроек дедупликации ошибок
     */
    static setErrorDeduplicationConfig(cooldownMs: number, maxCount: number) {
        Logger.errorCooldown = cooldownMs;
        Logger.maxErrorCount = maxCount;
    }
}

export default Logger;
