import {
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { Navigate } from 'react-router-dom';
import { useAccount } from 'wagmi';
import { ethers } from 'ethers';
import useWithdrawalContext from '../hooks/useWithdrawalContext';
import useGlobalContext from '../hooks/useGlobalContext';
import dsfAddresses from '../utils/dsf_addresses.json';
import dsfAbi from '../utils/dsf_abi.json';
import magicAddresses from '../utils/magic_addresses.json';
import magicNumbers from '../utils/magic_numbers.json';

// components
import WithdrawalNavigation from './withdrawal/WithdrawalNavigation';
import WithdrawalCard from './withdrawal/WithdrawalCard';
import FAQ from './withdrawal/FAQContainer';

const WithdrawalPage = () => {
  const {
    changeGasPrice,
    changeOptimizedGasPrice,
  } = useWithdrawalContext();
  const {
    ETHPrice,
    gasPrice,
  } = useGlobalContext();
  const {
    address,
    isConnecting,
  } = useAccount();
  // const provider = useRef(ethers.getDefaultProvider('https://rpc.ankr.com/eth'));
  // const provider = useRef(ethers.getDefaultProvider('https://ethereum-rpc.publicnode.com'));
  const provider = useRef(ethers.getDefaultProvider('https://eth-mainnet.g.alchemy.com/v2/FKY-30fKRX9qRcfGkoz2gBYHBYpeblXo'));


  const contract = useRef(new ethers.Contract(dsfAddresses.DSF, dsfAbi, provider.current));

  const estimateWithdrawalFeeInUsd = useCallback(async () => {
    const gasAmountBig = await contract.current.withdraw.estimateGas('995622405764805163', [0,0,1], 1, 2, {
      from: magicAddresses.MAGIC_FROM,
    });
    const gasAmount = ethers.formatUnits(gasAmountBig, 'gwei');
    const gasFee = +gasAmount * gasPrice;
    const gasFeeInUsd = gasFee * ETHPrice * magicNumbers.MAGIC_MULTIPLIER;

    return gasFeeInUsd;
  }, [gasPrice, ETHPrice]);

  const estimateOptimizedWithdrawalFeeInUsd = useCallback(async () => {
    const gasAmountBig = await contract.current.feesOptimizationWithdrawal.estimateGas(1, [0,0,1], {
      from: magicAddresses.MAGIC_FROM,
    });
    const gasAmount = ethers.formatUnits(gasAmountBig, 'gwei');
    const gasFee = +gasAmount * gasPrice;
    const gasFeeInUsd = gasFee * ETHPrice * magicNumbers.MAGIC_MULTIPLIER;

    return gasFeeInUsd;
  }, [gasPrice, ETHPrice]);

  useEffect(() => {
    estimateWithdrawalFeeInUsd().then(changeGasPrice);
    estimateOptimizedWithdrawalFeeInUsd().then(changeOptimizedGasPrice);
  }, [
    estimateWithdrawalFeeInUsd, estimateOptimizedWithdrawalFeeInUsd,
    changeOptimizedGasPrice, changeGasPrice,
  ]);

  if (!isConnecting && !address) return (<Navigate to="/deposit" replace />);

  return (
    <>
      <WithdrawalNavigation />
      <WithdrawalCard />
      <FAQ />
    </>
  )
};

export default WithdrawalPage;
