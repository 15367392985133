import React, { Component, ErrorInfo } from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from 'react-router-dom';
import './index.css';
import { Web3Modal } from '@web3modal/react';
import {
  configureChains,
  createConfig,
  mainnet,
  WagmiConfig,
  useAccount
} from 'wagmi';
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from '@web3modal/ethereum';
import * as buffer from 'buffer';
import './i18n';

// components
import Layout from './routes/Layout';
import ErrorPage from './routes/ErrorPage';
import DepositPage from './routes/DepositPage';
import WithdrawalPage from './routes/WithdrawalPage';
import DashboardPage from './routes/DashboardPage';
import TransactionsStatisticsPage from './routes/TransactionsStatisticsPage';

import Logger from './utils/logger';

declare global {
  interface Window {
    ethereum?: any;
  }
}

Logger.initGlobalErrorHandler();
// Logger.filterWarnings();

class ErrorBoundary extends Component<{ children: React.ReactNode }, { hasError: boolean }> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Logger.logError(error, errorInfo.componentStack ?? undefined);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Что-то пошло не так.</h1>;
    }
    return this.props.children;
  }
}

window.Buffer = buffer.Buffer;

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Navigate to="/deposit" replace />,
      },
      {
        path: 'deposit/:customPart?',
        element: <DepositPage />,
      },
      {
        path: 'withdraw',
        element: <WithdrawalPage />,
      },
      {
        path: 'dashboard',
        element: <DashboardPage />,
      },
      {
        path: 'transactions',
        element: <TransactionsStatisticsPage />,
      },
      {
        path: '/*',
        element: <Navigate to="/deposit" replace />,
      }
    ]
  },
]);

const chains = [mainnet];
const projectId = process.env.REACT_APP_WEB3_MODAL_PROJECT_ID || '';
const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: [
    ...w3mConnectors({ projectId, chains }),
    // new CoinbaseWalletConnector({
    //   chains,
    //   options: {
    //     appName: 'DSF',
    //   },
    // }),
    // new MetaMaskConnector({ chains }),
  ],
  publicClient
});
const ethereumClient = new EthereumClient(wagmiConfig, chains);

const APP_VERSION = '4.0.0'; 

const currentVersion = localStorage.getItem('app_version');
if (currentVersion !== APP_VERSION) {
  if ('caches' in window) {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  }
  localStorage.setItem('app_version', APP_VERSION);
}


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ErrorBoundary>
    <WagmiConfig config={wagmiConfig}>
      <RouterProvider router={router} />
    </WagmiConfig>
    <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
    </ErrorBoundary>
  </React.StrictMode>
);
